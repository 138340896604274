import * as React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { normalizedData } from "@utils";
import Seo from "@components/seo";
import Layout from "@layout";
import Header from "@layout/header/layout-01";
import Footer from "@layout/footer/layout-01";
import PageHeader from "@containers/page-header/layout-01";
import PartnerArea from "@containers/partner/layout-01";
import BoxSectionOne from "@components/BoxSection/layout-two/layout-6";
import BoxSectionTwo from "@components/BoxSection/layout-one/layout-4";
import Slider3D from "@containers/global/slider-3d";
import BoxSectionThree from "@components/BoxSection/layout-three/layout-3";
import CaseStudy from "@containers/global/case-study/layout-01";
import { caseStudiesData } from "@constants";
import ContactArea from "@containers/contact-us";

const ProfessionalTranslationPage = ({ location, data }) => {
  const content = normalizedData(data?.page.content || []);
  const globalContent = normalizedData(data?.allGeneral.nodes || []);

  return (
    <Layout location={location}>
      <Seo title="Professional Translation Services by Andovar" description="Create meaningful connections in any language with the help of Andovar's professional translation services. Contact us today for more information." />
      <Header
        data={{
          ...globalContent["menu"],
          ...data.site.siteMetadata,
        }}
      />
      <main className="site-wrapper-reveal">
        <PageHeader
          data={content["technical-translation-header-section"]}
          pageContext={content["technical-translation-meta-description"]}
        />
        <PartnerArea data={content["partner-section"]} />
        <BoxSectionOne layout={5} data={content["professional-translation-intro"]} />
        <BoxSectionTwo layout={5} data={content["app-marktings-body"]} />
        <Slider3D data={content["app-localization-applications"]} />
        <BoxSectionThree layout={5} data={content["app-marktingg-body"]} />
        <CaseStudy data={caseStudiesData} />
        <ContactArea Layout={2} />
      </main>
      <Footer data={{ ...data.site.siteMetadata }} />
    </Layout>
  );
};

export const query = graphql`
  query professionalTranslationPageQuery {
    allGeneral {
      nodes {
        section
        ...HeaderOne
      }
    }
    site {
      ...Site
    }
    page(
      title: { eq: "professional-translation" }
      pageType: { eq: "innerpage" }
    ) {
      content {
        ...PageContent
      }
    }
  }
`;

ProfessionalTranslationPage.propTypes = {
  location: PropTypes.shape({}),
  data: PropTypes.shape({
    allGeneral: PropTypes.shape({
      nodes: PropTypes.arrayOf(PropTypes.shape({})),
    }),
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        contact: PropTypes.shape({}),
      }),
    }),
    page: PropTypes.shape({
      content: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  }),
};

export default ProfessionalTranslationPage;
